<template>
  <div class="screencasts-block">
    <div class="vx-row -mx-2 flex-nowrap" v-if="(item.screencasts || []).length > 0">
      <div class="vx-col px-2 w-16">
        <vs-tooltip position="right" v-for="(size, i) in viewports || []" :key="i" :text="size">
          <vs-avatar
            size="medium"
            :color="size === currentScreenSize ? 'primary' : 'grey'"
            icon-pack="feather"
            :icon="getIconBySize(size)"
            @click="currentScreenSize = size"
          />
          <span></span>
        </vs-tooltip>
      </div>
      <div class="vx-col px-2 flex-1">
        <vx-card class="overflow-hidden w-full" v-if="screencast" :key="screencast.id">
          <div class="vs-component vs-con-input-label vs-input vs-input-primary w-full flex mb-1 justify-between">
            <label for="" class="vs-input--label">Job log</label>
          </div>
          <ul class="test-logs font-mono">
            <li v-for="(item, i) in steps" :key="i">
              <div
                class="test-logs__item"
                :class="{
                  'test-logs__item--step': item.step,
                  done: item.lastFrame <= currentFrame,
                  active: item.lastFrame > currentFrame && currentFrame > (steps[i - 1] || {}).lastFrame
                }"
              >
                <div class="step-block" v-if="item.step">
                  <strong @click="updatePlayerCurrentTime(screencast, item.step)">{{ item.step.description }}</strong>
                  <div class="step-results">
                    <span>PASSED:</span>
                    <feather-icon
                      :icon="item.step.passed ? 'CheckCircleIcon' : 'XCircleIcon'"
                      :svgClasses="['h-4 w-4', `${item.step.passed ? 'text-success' : 'text-danger'}`]"
                    />
                    <span class="ml-3">RAN:</span>
                    <feather-icon
                      :icon="item.step.ran ? 'PlayIcon' : 'StopCircleIcon'"
                      :svgClasses="['h-4 w-4', `${item.step.ran ? 'text-success' : 'text-danger'}`]"
                    />
                  </div>
                </div>
                <div
                  class="log-block"
                  v-for="(log, n) in item.logs"
                  :key="n"
                  @click="updatePlayerCurrentTime(screencast,log)"
                  :class="{ 'text-success': currentFrame >= log.frame && (item.logs[n + 1] || {}).frame > currentFrame }"
                >
                  <span class="linenumber">{{ log.index + 1 }}</span>
                  <p>{{ log.message }}</p>
                  <span class="timestamp text-xs">
                    <vs-icon icon-pack="feather" icon="icon-clock" size="10px"></vs-icon>
                    {{ log.timestamp | moment('timestamp') }}
                  </span>
                </div>
              </div>
            </li>
          </ul>
          <div class="test-results flex">
            <div class="relative text-xs font-mono ml-auto">
              RESULT:
              <span
                class="dot-count vs-avatar--badge-static"
                :style="{
                  background: screencast.passed ? 'rgb(70, 201, 58)' : 'rgb(255, 71, 87);'
                }"
                >{{ screencast.passed === undefined ? 'Running' :  (screencast.passed ? 'Passed' : 'Failed') }}</span
              >
            </div>
          </div>
          <!-- <pre>{{ steps }}</pre> -->
          <div class="vs-component vs-con-input-label vs-input vs-input-primary w-full mt-5">
            <label for="" class="vs-input--label"
              >Video log: <strong>{{ (currentLog || {}).url }}</strong></label
            >
          </div>
          
          <img v-if="screenPathImageUrl" :src="screenPathImageUrl" :style="{ maxWidth: '100%' }" />

          <div class="flex" v-if="!testRunning">
              <vs-button radius icon-pack="feather" :icon="(isPlaying ? 'icon-pause' : 'icon-play')" color="primary" @click="playVideo(screencast)" />
              <div style=""><small style="white-space: nowrap; ">Frame {{currentFrameIndex}} of {{totalFrameCount}}</small></div>
          <input v-if="!testRunning"
          type="range"
          v-model="videoProgress" @input="stopPlaying()"
          min="0"
          :max="totalFrameCount"  :style="{ width: '100%' }"
        />
          </div>


          <vue-plyr  v-if="!screencast.isImage" 
            :style="{ maxWidth }"
            ref="plyr"
            :options="plyrOptions"
            :emit="['ready', 'timeupdate']"
            @ready="onPlayerReady"
            @timeupdate="updatePlayTime"
          >
            <video :src="screencast.screencastPath"></video>
          </vue-plyr>
        </vx-card>
      </div>
    </div>
    <vs-alert v-else>No screencasts available</vs-alert>
  </div>
</template>

<script>
import VuePlyr from 'vue-plyr';
import { mapState } from 'vuex';
export default {
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    currentScreenSize: null,
    currentScreenPathImageUrl: null,
    currentFrameIndex: 0,
    playTime: 0,
    isPlaying: false,
    screenCastDuration: 0,
    videoProgress: 0,
    plyrOptions: {
      speed: {
        selected: 0.1,
        options: [0.1, 0.25, 0.5, 0.75, 1, 1.25, 1.5, 1.75, 2]
      },
      fullscreen: { enabled: false },
      controls: ['play-large', 'play', 'progress', 'current-time', 'settings']
    }
  }),
  computed: {
    ...mapState('captures', ['testRunning']),
    screenPathImageUrl() {
      if (this.currentScreenPathImageUrl) {
        return this.currentScreenPathImageUrl;
      }
      var ss = this.screencast;
      if (ss && ss.isImage) {
        return ss.screencastPath;
      }
      return null;
    },
    viewports() {
      const viewports = new Array();
      const item = this.item;

      function concatScreencasts(screencasts) {
        for (let i = 0; i < screencasts.length; i++) {
          const item = screencasts[i];
          const viewPort = item.viewPort;
          const exists = viewports.find(s => s.includes(`${viewPort.width}x`));
          if (!exists) {
            viewports.push(`${viewPort.width}x${viewPort.height}`);
          } else {
            const [w, h] = exists.split('x');
            if (Number(h) !== viewPort.height) {
              const indexOfMatchingWidth = viewports.indexOf(exists);
              viewports.splice(indexOfMatchingWidth, 1, `${w}x`);
            }
          }
        }
      }

      if (item && item.screencasts && item.screencasts.length > 0) concatScreencasts(item.screencasts);
      return viewports;
    },
    screencast() {
      if (!this.currentScreenSize) return null;
      const screencasts = this.item.screencasts;
      if (!screencasts || !(screencasts || []).length === 0) return null;
      return screencasts[this.viewports.indexOf(this.currentScreenSize)];
    },
    steps() {
      const { screencast } = this;
      if (!screencast) {
        return [];
      }
      var logsWithoutSteps = screencast.log ? screencast.log.reduce( (items, item, idx) => {
          if (typeof item.stepIndex === 'undefined' || item.stepIndex === null) {
              items.push({
                firstFrame: item.frame,
                lastFrame: item.frame,
                step: null,
                logs: [{...item, index: idx }],
              });
          }
        return items;
      }, []) : [];

      console.log('logsWithoutSteps', logsWithoutSteps );
      var logsWithSteps = screencast.steps.map( (step) => {
          var output = {
            step: step,
            logs: screencast.log ? screencast.log.filter( (logItem) => { return logItem.stepIndex === step.index } ).map( (item,idx) => 
              {
                return {...item, index: idx }
              }) : []
          }
          return output;
      });

      var outputs = [...logsWithoutSteps,...logsWithSteps];
      console.log('outputs', outputs);

      return outputs;

    },
    maxWidth() {
      try {
        return this.screencast.viewPort.width + 'px';
      } catch (error) {
        return '100%';
      }
    },
    totalFrameCount() {
      if (!this.screencast) return 0;
      try {
        if (this.screencast.frames) {
          return this.screencast.frames.length;
        }
        else {
          const logs = this.screencast.log;        
          const lastLog = logs[logs.length - 1];
          if (!lastLog) return 0;
          return lastLog.frame;
        }
      } catch (error) {
        return 0;
      }
    },
    currentFrame() {
      if (this.currentFrameIndex) {
        return this.currentFrameIndex;
      }
      else {
        const { playTime, totalFrameCount, screenCastDuration } = this;
        const percentTime = (playTime / screenCastDuration) * 100;
        const frame = (percentTime / 100) * totalFrameCount;
        return isNaN(frame) ? 0 : Math.round(frame);
      }
    },
    currentLog() {
      try {
        const { screencast, currentFrame } = this;
        const { log } = screencast;
        for (let i = 0; i < log.length; i++) {
          const item = log[i];
          console.log({ currentFrame, item });
          if (currentFrame === item.frame) return item;
          if (item.frame <= currentFrame && (log[i + 1] || {}).frame > currentFrame) return item;
        }
        return null;
      } catch (e) {
        console.error(e);
        return null;
      }
    }
  },
  methods: {
    playVideo(screencast) {
      var me = this;
      var { totalFrameCount } = this;
      var i = me.currentFrameIndex || 0 ;
      if (i == totalFrameCount ) {
        i=0;
      }

      if (me.isPlaying) {
        clearInterval(me.playingVideoInterval);
        me.isPlaying = false;
        return;
      }
      me.isPlaying = true;
      
      me.playingVideoInterval = setInterval( function() {
        if (i >= screencast.frames.length || !me.isPlaying) {
          me.isPlaying = false;
          clearInterval(me.playingVideoInterval);
          return;  
        }
        var frame = screencast.frames[i];
        me.$nextTick(() => {
          me.currentFrameIndex = i;
          me.currentScreenPathImageUrl = frame;
          //me.videoProgress = Math.ceil((me.currentFrameIndex / totalFrameCount) * 100);
          me.videoProgress = me.currentFrameIndex;
        });
        i++;        
      }, 300);

    },

    getIconBySize(size) {
      const [w] = size.split('x');
      return w > 768 ? 'icon-monitor' : 'icon-smartphone';
    },
    onPlayerReady() {
      const player = this.$refs && this.$refs.plyr ? this.$refs.plyr.player : null;
      if (player) player.speed = this.plyrOptions.speed.selected;
    },
    updatePlayerCurrentTime(screencast, log) {
      console.log('updatePlayerCurrentTime ...', log, screencast);
      const player = this.$refs && this.$refs.plyr ? this.$refs.plyr.player : null;

      var frame = log.frame || log.firstFrame;

      if (player && !screencast.isImage) {        
        const percentFrame = (frame / this.totalFrameCount) * 100;
        const seekTime = (percentFrame / 100) * player.duration;
        player.currentTime = seekTime;
      }
      else {        
        this.currentFrameIndex = frame;
        this.currentScreenPathImageUrl = log.frame < screencast.frames.length ? screencast.frames[frame] : null;
        //this.videoProgress = Math.ceil((this.currentFrameIndex / this.totalFrameCount) * 100);
        this.videoProgress = this.currentFrameIndex;
      }
    },
    updatePlayTime() {
      const player = this.$refs && this.$refs.plyr ? this.$refs.plyr.player : null;
      if (player) {
        this.playTime = player.currentTime;
        this.screenCastDuration = player.duration;
      }
    },
    stopPlaying() {
      console.log('stopPlaying');
      this.isPlaying = false;
    }
  },
  mounted() {
    this.$nextTick(() => {
      const viewports = this.viewports;
      if (viewports && viewports.length > 0) this.currentScreenSize = viewports[0];
    });
  },
  watch: {
    'videoProgress'() {
      var me = this;
      if (!this.screencast) return;

      if (!this.isPlaying) {
        me.currentFrameIndex = me.videoProgress;
        /*
          var { totalFrameCount } = this;
          var newFrameIndex =  Math.ceil ( (me.videoProgress / 100) * totalFrameCount );
          */
        var newFrameIndex =  me.currentFrameIndex;
        if (newFrameIndex <  me.screencast.frames.length ) {
            me.currentFrameIndex = newFrameIndex;
            me.currentScreenPathImageUrl = me.screencast.frames[newFrameIndex];
        }
      }
    },
    'screencast.screencastPath'() {
      this.$nextTick(() => {
        const plyr = this.$refs.plyr;
        if (plyr) {
          const player = plyr.player;
          if (!player) return;
          if (player.speed !== this.plyrOptions.speed.selected) this.onPlayerReady();
        }
      });
    },
    viewports(array) {
      this.$nextTick(() => {
        if (array && array.length > 0) this.currentScreenSize = array[0];
      });
    }
  },
  components: {
    VuePlyr
  }
};
</script>
